import * as React from "react"
import { Link, graphql } from "gatsby"
import PostList from "../components/postlist"
import Layout from "../components/layout"
import { addBottomLine } from "./styles.module.css"

const IndexPage = ({ data }) => {
  return (
    <Layout pageTitle="">
      <p>
        🏠 I am a Software Developer in India with 6+ years of experience
        <br />
        🏢 Currently working as a Product Engineering Lead in Bangalore
      </p>
      <p className={addBottomLine}>
        <a href="https://angel.co/abhnv" target="_blank">
          Angel.co
        </a>{" "}
        |{" "}
        <a href="https://www.linkedin.com/in/abhnvkmr" target="_blank">
          LinkedIn
        </a>{" "}
        |{" "}
        <a rel="me" href="https://mastodon.social/@abhnv" target="_blank">
          Mastodon
        </a>{" "}
        |{" "}
        <a href="https://github.com/abhbh" target="_blank">
          GitHub
        </a>{" "}
        |{" "}
        <a href="https://www.instagram.com/abhnvkmr" target="_blank">
          Instagram
        </a>{" "}
        |{" "}
        <a href="https://twitter.com/abhnvkmr" target="_blank">
          Twitter
        </a>
      </p>
      <h2>Recent Posts</h2>
      <PostList posts={data.allMdx.nodes} />
      <p className={addBottomLine}>.</p>
      <p>
        View all <Link to="/blog/">Blogposts</Link>, <Link to="/haikus/">Haikus</Link>,{" "}
        <Link to="/prose/">Stories and Poems</Link>.
      </p>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 15) {
      nodes {
        frontmatter {
          title
          cat
          date(formatString: "MMMM DD, YYYY")
        }
        id
        slug
      }
    }
  }
`

export default IndexPage
