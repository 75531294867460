import * as React from "react"
import { Link } from "gatsby"
import { postlistContainer } from "./postlist.module.css"

const PostList = ({ posts }) => {
  return (
    <div className={postlistContainer}>
      {posts.map((post) => (
        <div key={post.id}>
          <h3>
            <Link to={`/${post.frontmatter.cat}/${post.slug}`}>{post.frontmatter.title}</Link>
          </h3>
          <p>
            Posted on {post.frontmatter.date} in {post.frontmatter.cat}
          </p>
        </div>
      ))}
    </div>
  )
}

export default PostList
