import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import {
  container,
  innerContainer,
  footer,
  activeLink,
  socialIcon,
  socialIconSvg,
} from "./layout.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitterSquare,
  faInstagramSquare,
  faLinkedin,
  faGithubSquare,
  faFacebookSquare,
  faMastodon
} from "@fortawesome/free-brands-svg-icons"

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={container}>
      <title>
        {data.site.siteMetadata.title} | {pageTitle}
      </title>

      <aside>
        <h2>Hi.</h2>
        <h3>
          I'm <Link to="/">Abhinav Kumar</Link>.
        </h3>
        <p>
          🖥️ Geek, Product Engineer, Listener, Polyglot
          <br />
          🧡 History, Epic Fantasy, RPG & OSS. Tries to 🎨✍️🎙️occasionally
          <br />
          🗣️ Open for Chats, Rants & Chants
        </p>
        <p>
          View all my blogposts at{" "}
          <Link to="/blog/" activeClassName={activeLink}>
            archive here.
          </Link>
        </p>
        <p>
          Occasionally I also write{" "}
          <Link to="/haikus/" activeClassName={activeLink}>
            Haikus
          </Link>
          ,{" "}
          <Link to="/prose/" activeClassName={activeLink}>
            Stories and Poems
          </Link>
          .
        </p>
        <p>
          Read my tech blog at{" "}
          <a href="https://abhnv.dev" target="_blank">
            abhnv.dev
          </a>
        </p>
        <p>
          Read and subscribe to my weekly newsletter at{" "}
          <a href="https://interrupt.substack.com" target="_blank">
            interrupt.substack.com
          </a>
        </p>
        <p>
          Find me on the Fediverse{" "}
          <a href="https://mastodon.social/@abhnv" target="_blank">
            @abhnv
          </a>
        </p>
        <p>
          <a href="https://mastodon.social/@abhnv" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faMastodon} size="1x" />
          </a>
          <a href="https://www.instagram.com/abhnvkmr" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faInstagramSquare} size="1x" />
          </a>
          <a href="https://www.facebook.com/abhnvkmr" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faFacebookSquare} size="1x" />
          </a>
          <a href="https://github.com/abhbh" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faGithubSquare} size="1x" />
          </a>
          <a href="https://www.linkedin.com/in/abhnvkmr" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faLinkedin} size="1x" />
          </a>
          <a href="https://twitter.com/abhnvkmr" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faTwitterSquare} size="1x" />
          </a>
        </p>
      </aside>

      <main>
        <h1>{pageTitle}</h1>
        <div>{children}</div>
      </main>

      <footer className={footer}>
        © {new Date().getFullYear()} Abhinav Kumar — Built with{" "}
        <a href="https://www.gatsbyjs.org">GatsbyJS</a> — Based on{" "}
        <a href="https://github.com/swanson/lagom">Lagom</a>
      </footer>
    </div>
  )
}

export default Layout
